import { Seo } from '@/layout/Seo';
import { Fragment } from 'react';
import { ServiceHero } from '@/ServiceHero';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { px } from '@/layout/GlobalStyles';

const Grid = styled.section`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    max-width: 1232px;
    margin: 0 auto;
    box-sizing: content-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, min(100%, 608px));
    gap: 16px;

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

const Card = styled.div`
    text-align: center;

    > h4 {
        margin: 16px 0;
        color: #333333;
    }

    > a {
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus {
            opacity: 0.7;
        }
    }
`;

export default function ShopPage() {
    return (
        <Fragment>
            <Seo
                title="Shop Our Recommended Products - Integrate Institute"
                description="Here is the list of our favorite products that we recommend to our clients."
            />
            <ServiceHero />
            <Grid>
                <Card>
                    <StaticImage
                        src="../../../assets/images/shop/acumobility-ball.jpg"
                        alt="Acumobility Massage Ball"
                        width={608}
                        height={680}
                    />
                    <h4>Acumobility Massage Ball</h4>
                    <a href="https://amzn.to/3Kvz6Ok" target="_blank" rel="noopener noreferrer">
                        <StaticImage
                            src="../../../assets/images/amazon.png"
                            alt="amazon.com link"
                            width={128}
                            height={40}
                        />
                    </a>
                </Card>
                <Card>
                    <StaticImage
                        src="../../../assets/images/shop/wsakoue-band.jpg"
                        alt="WSAKOUE Pull Up Assistance Bands"
                        width={608}
                        height={680}
                    />
                    <h4>WSAKOUE Pull Up Assistance Bands</h4>
                    <a href="https://amzn.to/3Kuy6tM" target="_blank" rel="noopener noreferrer">
                        <StaticImage
                            src="../../../assets/images/amazon.png"
                            alt="amazon.com link"
                            width={128}
                            height={40}
                        />
                    </a>
                </Card>
                <Card>
                    <StaticImage
                        src="../../../assets/images/shop/lacrosse-balls.jpg"
                        alt="Champion Sports Official Lacrosse Balls"
                        width={608}
                        height={680}
                    />
                    <h4>Champion Sports Official Lacrosse Balls</h4>
                    <a href="https://amzn.to/3Z0Utf6" target="_blank" rel="noopener noreferrer">
                        <StaticImage
                            src="../../../assets/images/amazon.png"
                            alt="amazon.com link"
                            width={128}
                            height={40}
                        />
                    </a>
                </Card>
                <Card>
                    <StaticImage
                        src="../../../assets/images/shop/champion-ball.jpg"
                        alt="Champion Sports Official Lacrosse Balls"
                        width={608}
                        height={680}
                    />
                    <h4>Champion Sports Official Lacrosse Balls</h4>
                    <a href="https://amzn.to/3ZficYt" target="_blank" rel="noopener noreferrer">
                        <StaticImage
                            src="../../../assets/images/amazon.png"
                            alt="amazon.com link"
                            width={128}
                            height={40}
                        />
                    </a>
                </Card>

                <Card>
                    <StaticImage
                        src="../../../assets/images/shop/foam-roller.jpg"
                        alt="LuxFit Speckled Foam Rollers for Muscles"
                        width={608}
                        height={680}
                    />
                    <h4>LuxFit Speckled Foam Rollers for Muscles</h4>
                    <a href="https://amzn.to/3m1jHLG" target="_blank" rel="noopener noreferrer">
                        <StaticImage
                            src="../../../assets/images/amazon.png"
                            alt="amazon.com link"
                            width={128}
                            height={40}
                        />
                    </a>
                </Card>
                <Card>
                    <StaticImage
                        src="../../../assets/images/shop/luxfit-foam-roller.jpg"
                        alt="LuxFit Speckled Foam Rollers for Muscles"
                        width={608}
                        height={680}
                    />
                    <h4>LuxFit Speckled Foam Rollers for Muscles</h4>
                    <a href="https://amzn.to/3YVF8Mz" target="_blank" rel="noopener noreferrer">
                        <StaticImage
                            src="../../../assets/images/amazon.png"
                            alt="amazon.com link"
                            width={128}
                            height={40}
                        />
                    </a>
                </Card>
            </Grid>
        </Fragment>
    );
}
